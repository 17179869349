@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Raleway:wght@300;400;500;600;700;800;900&family=Roboto&display=swap');

@layer base {
    body{
        @apply font-[raleway];
    }
    li{
        @apply px-4;
        @apply cursor-pointer
    }
}